import React, { FC } from "react"
// import { Link } from "gatsby"
import Head from "../../components/head"
import Header from "../../components/header"
import Footer from "../../components/footer"
import styles from "../../styles/global.css"
import contact from "../../styles/media_page.css"
import media from "../../styles/media.css"

export default () => (
  <>
    <Header titleText="Media - Happy Mango" />
    <div className="main">
      <div className="PC_head">
        <div className="logo_div">
          <a rel="" href="/" className="logo" title="Happy Mango">
            <img
              className="light"
              src="/images/hm-logo-v2.png"
              alt="Happy Mango"
            />
          </a>
        </div>
        <ul className="nav_ul">
          <li className="nav_li">
            <a rel="" href="/services/">
              Our Services
            </a>
            <ul className="nav_ul_son">
              <li className="nav_li_son">
                <a rel="" href="/services/#Digitalize">
                  Front to Back Digitalization
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Customize">
                  Customized Underwriting
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Expand">
                  Digital Marketing Tools
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Improve">
                  Financial Health Management
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Track">
                  Impact Analysis and Reporting
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/clients/">
              Our Clients
            </a>
            <ul className="nav_ul_son nav_ul_son_se">
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Banks
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Credit Unions
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  CDFIs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/why/">
              Why Happy Mango?
            </a>
          </li>
        </ul>
        <div className="clear_both"></div>
        <div className="loginheader_div">
          <div className="loginheader">
            <div className="loginheaderlist_div">
              <img
                alt="icon doller"
                src="/sites/default/files/icon_doller.png"
              />
              <a rel="" href="/pricing/">
                Pricing
              </a>
            </div>
            <div className="loginheaderlist_div">
              <img alt="icon trial" src="/sites/default/files/icon_trial.png" />
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=0"
                target="_blank"
              >
                Free Trial
              </a>
            </div>

            <div className="loginheaderlist_div">
              <img alt="user" src="/sites/default/files/user.png" />
              <span className="login_span">
                <a rel="" href="javascript:;">
                  Log In
                </a>
                <ul className="login_ul_son">
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://user.happymangocredit.com"
                      target="_blank"
                    >
                      Individual/Business
                    </a>
                  </li>
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://institution.happymangocredit.com"
                      target="_blank"
                    >
                      Financial Institutions
                    </a>
                  </li>
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://advisor.happymangocredit.com"
                      target="_blank"
                    >
                      Financial Coaches
                    </a>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </div>

      <Head />

      {/* <div className="banner_contact">
        <img
          className="contact_img"
          src="/images/fresh-leaves.jpg"
          alt="banner"
        />
        <h1 className="bannerinfo">Mango Slice Blog</h1>
      </div> */}

      <div className="media">


<div id="preface" className="block">
  <div className="region region-preface">
    <div id="block-views-security-block" className="block block-views blue we-believe">
      <div className="inner">
        <div className="content">
          <div className="view view-security view-id-security view-display-id-block view-dom-id-09b08ef9d2ef556de55f0586d9ed9143">
            <div className="view-content">
              <div className="item">
                <div>
                  <div className="title">
                    <div
                        style={{
                          "margin-top": "-10px",
                          "text-align": "left",
                          "font-size": "28px",
                          "font-weight": "bold",
                        }}
                    >
                      <h3>Record Profits? Invest In Your Future</h3>
                    </div>
                    <div>
                      <div>
                        <div className="excerpt"
                          style={{
                            display: "block",
                            "text-align": "right",
                            "font-size": "14px",
                          }}
                        >
                          <img src="/sites/all/themes/hmm/images/blogs/date-gray.png"
                            style={{ width: "16px" }}
                          /> Oct 19, 2018
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <img src="/sites/all/themes/hmm/images/blogs/0.jpg"
                  style={{ width: "100%", 'margin-bottom': '20px' }}
                />
                <div className="sharethis-inline-share-buttons"></div>
                <div>
                  <div className="title">Community banks should seize the moment to innovate in product and process.</div>
                </div>
                <div>
                  <p> With less than a quarter to go, 2018 is shaping out to be a banner year for community banks. Record profits, a solid and growing capital base, major regulatory relief – there are no looming threats that might reverse these favorable trends. Nevertheless, the number of community banks continues to decline and competitive pressure from credit unions and large banks appears to only intensify. <b>Now is the time to deploy some of those record profits and excess capital to securing your future.</b>
                  </p>
                  <div>
                    <img src="/sites/all/themes/hmm/images/blogs/existential risk despite record profits.png"
                      style={{ width: "100%" }}
                    />
                  </div>
                  <p>
                  <div className="title">Think Consumer: Leverage your community advantage to uncover new revenue opportunities</div>
                  </p>
                  <p> Conventional wisdom has it that community banks should stay out of consumer lending – territory traditionally occupied by large banks and credit unions. Since community banks have neither the scale of large banks to disperse processing costs nor the “common bond” of credit unions to contain default loss, consumer lending has not been traditionally viable for community banks. </p>
                  <p> This view should be re-examined because scale and “common bond” are no longer the only ways to overcome processing costs and default loss. New lending technology can minimize both. A large and growing number of consumers are in need of affordable cash-flow management products – a multi-billion market in the form of small-dollar short-term credit products – that community banks are well-positioned to offer. </p>
                  <p> Consider the following statistics from <a href="https://www.jpmorganchase.com/corporate/institute/research.htm" target="_blank" rel="nofollow noopener">research by JP Morgan Institute</a>: </p>
                  <ul>
                    <li>
                      <a href="https://www.jpmorganchase.com/corporate/institute/report-paychecks-paydays-and-the-online-platform-economy.htm" target="_blank" rel="nofollow noopener">55% of consumers experience more than 30%</a> change in month-to-month income
                    </li>
                    <li>The <a href="https://www.jpmorganchase.com/corporate/institute/report-coping-with-costs.htm" target="_blank" rel="nofollow noopener">median month-to-month spending change is $1297</a>, the equivalent of one month’s rent in most metro areas.&nbsp; </li>
                  </ul>
                  <p>Many of these consumers earn more than adequate income to support their living but struggle with liquidity challenges due to their income and spending volatility. They need an easy way to access emergency cash but cannot get it from banks or credit unions because their credit scores are often too low or non-existent.</p>
                  <p>
                    <img src="/sites/all/themes/hmm/images/blogs/small-dollar loans made in 2015.png"
                      style={{
                        width: '320px',
                          float: 'left',
                          'margin-right': '20px',
                          'margin-bottom': '20px',
                        'margin-top': '0px',
                      }}
                    /> Community banks have strong relationships with small businesses. Small businesses, according to the
                      <a href="https://www.sba.gov/sites/default/files/FAQ_Sept_2012.pdf" target="_blank" rel="nofollow noopener">Small Business Administration</a>
                      (SBA), provide 64% of net new private-sector jobs, 49.2% of private-sector employment, and 42.9% of private-sector payroll.
                      Through small business employers, community banks can reach the millions of consumers who are in need of affordable financial products.
                      And with the right technology, community banks can establish a foothold in this multi-billion dollar market and gain a competitive edge against
                      credit unions and banks tethered to their legacy consumer lending practice.
                  </p>
                  <div  
                    style={{ clear: 'both' }}
                  ></div>
                  <p>
                  <div className="title">Set a Risk Appetite for innovation</div>
                  </p>
                  <p>As the budgeting season gets underway, bank executives should have a discussion about setting aside capital for innovation. Successful innovation is built on learning gained from failed experiments. Knowing how much you are willing to lose not only sets the guideline in internal execution but also helps with the communication to external regulators.</p>
                  <p>An average community bank’s annual pre-tax spending in technology is about 0.15% of total assets. Given the current ROA at 1.18%, a 50% increase in the technology budget shaves a small amount from the bottom-line but can open up significant new revenue opportunities, such as consumer lending.</p>
                  <p>2018 has been a great year. Make it the jumping-off point for an even better future. Take advantage of the new financial technology, leverage your community network, and find the hidden gems in the market-- Set aside the capital and draw up the plan to innovate now.
                  <p>How we can help: <a href="https://calendly.com/kate-hao_happymango/happy-mango-30-minute-online-demo" className="reqbtn">Request Demo</a>
                  </p>
                  <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  </>
)
